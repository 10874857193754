import React from 'react';
import PropTypes from 'prop-types';

import Card from '@components/containers/Card';
import FlexBox from '@components/containers/FlexBox';
import ParagraphWithIcon from '@components/containers/ParagraphWithIcon';

import Paragraph from '@components/elements/Paragraph';
import Headline from '@components/elements/Headline';
import Anchor from '@components/elements/Anchor';

import onTimeIcon from '@assets/img/icons/on-time.svg';
import lateIcon from '@assets/img/icons/late.svg';

import numberFormat from '@utils/numberFormat';

const AccountCard = ({
  title,
  loading,
  mainAmountTitle,
  mainAmountValue,
  mainAmountIcon,
  monthlyPaymentTitle,
  monthlyPaymentValue,
  badges,
  viewMoreUrl,
  passedInvoicesPayed,
}) => (
  <Card loading={loading}>
    <FlexBox alignItems="center" flexWrap="wrap" justifyContent="space-between">
      <FlexBox alignItems="center" marginRight="small">
        <Headline fontWeight="bold" fontSize="tiny" as="h2" marginBottom="none" marginRight="small">{title}</Headline>
        <FlexBox flexDirection="row" gap="small">
          {badges}
        </FlexBox>
      </FlexBox>
      <Anchor underlined to={viewMoreUrl}>Visa mer</Anchor>
    </FlexBox>
    <FlexBox justifyContent="space-between">
      <FlexBox flexDirection="column" alignItems="flex-start">
        <Paragraph fontSize="small" marginBottom="small">{mainAmountTitle}</Paragraph>
        <ParagraphWithIcon icon={mainAmountIcon} fontWeight="bold" fontSize="small">{numberFormat(mainAmountValue, 2, { unit: 'kr' })}</ParagraphWithIcon>
      </FlexBox>
      <FlexBox flexDirection="column" alignItems="flex-end">
        <Paragraph fontSize="small" marginBottom="small">{monthlyPaymentTitle}</Paragraph>
        {passedInvoicesPayed
          ? <ParagraphWithIcon icon={onTimeIcon} fontWeight="bold" fontSize="small" marginTop="none">{numberFormat(monthlyPaymentValue, 2, { unit: 'kr' })}</ParagraphWithIcon>
          : <ParagraphWithIcon icon={lateIcon} colour="tertiaryFailure" fontWeight="bold" fontSize="small" marginTop="none">{numberFormat(monthlyPaymentValue, 2, { unit: 'kr' })}</ParagraphWithIcon>}
      </FlexBox>
    </FlexBox>
  </Card>
);

AccountCard.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  mainAmountTitle: PropTypes.string.isRequired,
  mainAmountValue: PropTypes.number.isRequired,
  mainAmountIcon: PropTypes.node.isRequired,
  monthlyPaymentTitle: PropTypes.string.isRequired,
  monthlyPaymentValue: PropTypes.number.isRequired,
  badges: PropTypes.arrayOf(PropTypes.node).isRequired,
  viewMoreUrl: PropTypes.string.isRequired,
  passedInvoicesPayed: PropTypes.bool.isRequired,
};

export default AccountCard;
